<template lang="pug">
	b-list-group-item.notification-item(:class="{'not-read': !notification.is_read}")
		.icon-container
			button.btn.icon(@click="toggle()", type="button", :class="notification._selected ? 'selected' : 'icon-'+notification.type")
				i.la(:class="icon")
		.content
			h5.mb-1 {{ notification.rule ? notification.rule.name : notification.title }} &nbsp;
				b-badge.text-capitalize(v-if="notification.badge", :variant="variant") {{ notification.badge }}
			p.mb-1 {{ notification.body }}
			p.mb-1(v-if="notification.offer")
				| Offer
				router-link.ml-1.mr-1(:to="{name:'offer-edit', params: {id:notification.offer_id}}") \#{{ notification.offer_id }}
				| {{ notification.offer.name }} | {{ notification.offer.advertiser.name }}
			p.mb-1(v-if="notification.rule")
				router-link(:to="{name:'kpi-rules', query:{rule_id: notification.rule_id}}") Rule Settings
		.date-container
			small.date {{ notification.created_at }}
</template>
<script>
export default {
  name: 'notification-item',
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },

  computed: {
    icon() {
      if (this.notification._selected) {
        return 'la-check';
      }
      switch (this.notification.type) {
        case 'info':
          return 'la-info';
        case 'new':
          return 'la-star';
        case 'alert':
          return 'la-exclamation';
        case 'warning':
          return 'la-exclamation-triangle';
        case 'rule':
          return 'la-magic';
        default:
          return 'la-star';
      }
    },
    variant() {
      switch (this.notification.type) {
        case 'info':
          return 'info';
        case 'new':
          return 'primary';
        case 'alert':
          return 'danger';
        case 'warning':
          return 'warning';
        case 'rule':
          return 'info';
        default:
          return 'dark';
      }
    },
  },

  methods: {
    toggle() {
      this.$emit('toggle');
    },
  },
};
</script>
<style lang="scss">
.notification-item {
  width: 100%;
  display: flex;
  flex-direction: row;

  .icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .icon {
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      display: inline-flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      vertical-align: middle;
      flex-shrink: 0;
      font-size: inherit;
      font-weight: 400;
      line-height: 1;
      max-width: 100%;
      text-align: center;
      overflow: visible;
      position: relative;

      i {
        font-size: calc(1.4rem);
        white-space: nowrap;
        width: 60%;
        height: auto;
        max-width: 100%;
      }

      color: #fff;
      background-color: #6c757e;

      &.selected {
      }

      &.icon-new {
        color: #fff;
        background-color: #007bff;
      }
      &.icon-alert {
        color: #fff;
        background-color: #dc3545;
      }
      &.icon-info {
        color: #fff;
        background-color: #17a2b8;
      }
      &.icon-warning {
        color: #fff;
        /*background-color: #ec9602;*/
        background-color: #343a40;
      }
    }
  }
  .content {
    flex: 1;
    padding: 0 15px;
    p {
      white-space: pre-line;
    }
  }
  .date-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.not-read {
    background: #efefef;
    .content {
      font-weight: bold;
      h5 {
        font-weight: bolder;
      }
    }
  }
}
</style>
